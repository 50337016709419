@tailwind base;
@tailwind components;
@tailwind utilities;

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right-style: hidden;
}

.ngx-json-viewer {
    overflow-y: scroll !important;
}

html,
body,
app-root {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1 {
    margin: 0 !important;
}

h2 {
    margin: 0 !important;
}

//RISK
$VeryLowRisk: rgb(64, 154, 106);
$LowRisk: rgb(109, 199, 151);
$ModerateRisk: rgb(243, 197, 52);
$HighRisk: rgb(247, 148, 29);
$VeryHighRisk: rgb(238, 46, 36);

.VeryLowRisk {
    background-color: $VeryLowRisk;
}

.LowRisk {
    background-color: $LowRisk;
}

.ModerateRisk {
    background-color: $ModerateRisk;
}

.HighRisk {
    background-color: $HighRisk;
}

.VeryHighRisk {
    background-color: $VeryHighRisk;
}